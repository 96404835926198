import React, { useContext, useState, useEffect } from 'react'
import HomeContainer from '../../components/HomeContainer'
import HomeContent from '../../components/HomeContent'
import GroupHeader from '../../components/GroupHeader'
import HomeInput from '../../components/HomeInput'
import HomeLogo from '../../components/HomeLogo'
import { ICol, IRow } from '../../components/Grid'
import HomeButton from '../../components/HomeButton'
import { AuthContext } from '../../context/AuthContext'
import { types } from '../../types/types'
import { useForm } from '../../hooks/useForm'
import Loader from '../../components/Loader/Loader'
import { URL_BASE_API } from '../../settings/urlBaseApi'
import { helpHttp } from '../../helpers/helpHtpp'
import Mensaje from '../../components/Mensaje/Mensaje'
import ErrorInput from '../../components/ErrorInput/ErrorInput'
import styled from 'styled-components'
import { Modal as ModalAnt } from 'antd'

const validationsForm = (formValues) => {
  let errors = {}

  if (!formValues.usuario.trim()) {
    errors.usuario = "El campo 'Usuario' es requerido"
  }

  if (!formValues.password.trim()) {
    errors.password = "El campo 'Password' es requerido"
  }

  return errors
}

const LoginView = ({ history, ...props }) => {
  const { dispatch } = useContext(AuthContext)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [errorsForm, setErrorsForm] = useState({})
  const [showPassword, setShowPassword] = useState(false)
  const [permisosConcedidos, setPermisosConcedidos] = useState(false)
  const [permisosDenegados, setPermisosDenegados] = useState(false)
  const [showLocationDeniedMessage, setShowLocationDeniedMessage] =
    useState(false)
  const [loginButtonClicked, setLoginButtonClicked] = useState(false)
  const [cierresPendientes, setCierresPendientes] = useState(false)
  const handleInputBlur = (e) => {
    handleInputChange(e)
    setErrorsForm(validationsForm(formValues))
  }

  const [formValues, handleInputChange] = useForm({
    usuario: '',
    password: '',
  })
  const { usuario, password } = formValues

  let url = URL_BASE_API + '/login'

  const solicitarPermisosGPS = () => {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          console.log('Permiso de GPS concedido:', position)
          setPermisosConcedidos(true)
        },
        function (error) {
          console.error(error)
          setPermisosDenegados(true)
          setShowLocationDeniedMessage(true)
        },
        options
      )
    } else {
      console.error('No se concendieron permisos GPS')
    }
  }

  const handleLogin = (e) => {
    e.preventDefault()

    if (!loginButtonClicked) {
      return
    }

    const erroresLocales = validationsForm(formValues)
    setErrorsForm(erroresLocales)

    if (Object.keys(erroresLocales).length === 0) {
      const lastPath = localStorage.getItem('lastPath') || '/'
      setLoading(true)
      helpHttp()
        .post(url, {
          body: formValues,
        })
        .then((res) => {
          if (res !== undefined) {
            if (!res.err) {
              setCierresPendientes(res.cierresPendientes)
              setError(null)
              dispatch({
                type: types.login,
                payload: {
                  token: res.access_token,
                },
              })
              localStorage.setItem('ruta', res.ruta)
              localStorage.setItem('cupo', res.cupo)
              // localStorage.setItem('codigoPais', res.codigoPais)
              localStorage.setItem('cantidadCuotas', res.cantidad_cuotas)
              localStorage.setItem('usarGpsAsesor', res.usar_gps_asesor)
              history.replace(lastPath)
            } else {
              if (res.status === 400) {
                res.statusText = 'Usuario sin ruta o deshabilitado'
              } else if (res.status === 431) {
                res.statusText = 'Usuario o contraseña incorrecto'
              }
              setError(res)
            }
          }

          setLoading(false)
        })
    } else {
      return
    }
  }

  useEffect(() => {
    if (cierresPendientes) {
      ModalAnt.warning({
        title: 'Tiene cierres pendientes',
        content: 'Hay cierres pendientes. Por favor revisar',
        okText: 'Aceptar',
      })
    }
  }, [cierresPendientes])

  const handleTogglePassword = () => {
    setShowPassword(!showPassword)
  }

  const handleReloadPage = () => {
    window.location.reload(true)
  }

  const handleClick = () => {
    if (!loginButtonClicked) {
      solicitarPermisosGPS()
    }
  }

  //Verificación y manejo de mensajes y visibilidad de acuerdo al estado de los permisos de ubicación
  useEffect(() => {
    const checkGeolocationPermission = async () => {
      try {
        const result = await navigator.permissions.query({
          name: 'geolocation',
        })
        if (result.state === 'granted') {
          setPermisosConcedidos(true)
        } else if (result.state === 'prompt') {
          setShowLocationDeniedMessage(false)
        } else {
          setPermisosDenegados(true)
          setShowLocationDeniedMessage(true)
        }
      } catch (error) {
        console.error('Error al verificar permisos:', error)
      }
    }

    const isReloadedManually = performance === 1
    if (permisosDenegados && isReloadedManually) {
      setShowLocationDeniedMessage(true)
    } else {
      checkGeolocationPermission()
    }
  }, [permisosDenegados])

  const ShowPasswordLabel = styled.label`
    color: #fff;
    font-weight: bold;
    margin-left: 5px;
  `

  const ButtonContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    grid-gap: 5px;
    align-items: center;
    justify-items: center;
    margin-top: 5cm;
  `

  const StyledButton = styled.button`
    border: 1px solid #fff;
    color: ${(props) => (props.denied ? 'red' : '#fff')};
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    background-color: transparent;
    width: auto;
    margin-top: 1px;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  `

  const VersionText = styled.div`
    color: #fff;
    font-size: 16px;
    margin-top: 5px;
    text-align: center;
  `

  return (
    <div {...props}>
      <HomeContainer>
        <GroupHeader simple />
        <HomeContent>
          <IRow w={70} align="center" h={40} valign="center" justify="center">
            <ICol>
              <ICol mb={4}>
                <HomeLogo />
              </ICol>
              {error && (
                <Mensaje
                  mensaje={`Error ${error.status}: ${error.statusText}`}
                  type="error"
                  title="Error"
                />
              )}
              {loading && <Loader />}
              <form onSubmit={handleLogin}>
                <HomeInput
                  name="usuario"
                  placeholder="Usuario"
                  value={usuario}
                  handleInputChange={handleInputChange}
                  handleInputBlur={handleInputBlur}
                />
                {errorsForm.usuario && (
                  <ErrorInput textoError={errorsForm.usuario} />
                )}
                <HomeInput
                  name="password"
                  placeholder="Contraseña"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  handleInputChange={handleInputChange}
                  handleInputBlur={handleInputBlur}
                />
                <input
                  type="checkbox"
                  id="showPassword"
                  checked={showPassword}
                  onChange={handleTogglePassword}
                />
                <ShowPasswordLabel htmlFor="showPassword">
                  Mostrar contraseña
                </ShowPasswordLabel>
                {errorsForm.password && (
                  <ErrorInput textoError={errorsForm.password} />
                )}
                <ICol mt={1}>
                  <HomeButton onClick={() => setLoginButtonClicked(true)}>
                    Entrar
                  </HomeButton>
                </ICol>
                <ICol mt={10}>
                  <ButtonContainer>
                    <StyledButton onClick={handleReloadPage}>
                      Limpiar caché
                    </StyledButton>
                    {!permisosConcedidos && permisosDenegados && (
                      <StyledButton
                        onClick={handleClick}
                        denied={showLocationDeniedMessage}
                        disabled={loginButtonClicked}
                      >
                        No se concedieron permisos de ubicación
                      </StyledButton>
                    )}
                    {!permisosConcedidos && !permisosDenegados && (
                      <StyledButton
                        onClick={handleClick}
                        disabled={loginButtonClicked}
                      >
                        Permitir ubicación
                      </StyledButton>
                    )}
                  </ButtonContainer>
                  <VersionText>Versión: 4.9.6</VersionText>
                </ICol>
              </form>
            </ICol>
          </IRow>
        </HomeContent>
      </HomeContainer>
    </div>
  )
}

export default LoginView
