//Import
import React, { useEffect, useReducer, useState } from 'react'

//Layouts
import GroupHeader from '../../components/GroupHeader'
import GroupTableContainer from '../../components/GroupTableContainer'
import GroupItemGen from '../../components/GroupItemGen'
import GroupTableHeader from '../../components/GroupTableHeader'
import NavBar from '../../components/NavBar'

//vars
import ofc from '../../settings/variables'
import Loader from '../../components/Loader/Loader'
import Mensaje from '../../components/Mensaje/Mensaje'
import { URL_BASE_API } from '../../settings/urlBaseApi'
import { helpHttp } from '../../helpers/helpHtpp'
import {
  creditosInitialState,
  creditosReducer,
} from '../../reducers/creditosReducer'
import { types } from '../../types/types'

import { message } from 'antd'
import { formatNumber } from '../../helpers/formatNumber'
import { Modal as ModalAnt } from 'antd'
import ModalCredito from '../../components/ModalCredito/ModalCredito'
import Modal from '../../components/Modal/Modal'
import ModalEnrutar from '../../components/ModalEnrutar/ModalEnrutar'
import { useForm } from '../../hooks/useForm'

const validationsForm = (formValues) => {
  let errors = {}
  let regexNumeroEnteroMayorCero = /^[0-9]\d*$/
  let regexNumeroEntero = /^[1-9]\d*$/
  let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/
  let regexTamano = /^.{3,120}[0-9A-Za-zÑñÁáÉéÍíÓóÚúÜü,\s]+$/
  let regexTamanoCelular = /^.{6,120}$/
  const cupo = localStorage.getItem('cupo')

  if (!formValues.capital.trim()) {
    errors.capital = "El campo 'Capital' es requerido"
  } else if (!regexNumeroEntero.test(formValues.capital.trim())) {
    errors.capital = "El campo 'Capital' debe mayor a cero sin decimales"
  } else if (cupo > '0') {
    if (parseInt(formValues.capital) > parseInt(cupoState)) {
      errors.capital = `El 'Capital' excede el cupo ${formatNumber(cupoState)}`
    }
  }

  /*if (!formValues.interes.trim()) {
    errors.interes = "El campo 'Interés' es requerido";
  } else if (!regexNumeroEnteroMayorCero.test(formValues.interes.trim())) {
    errors.interes = "El campo 'Interés' debe mayor a cero sin decimales";
  }*/

  if (!formValues.enrutarCredito.trim()) {
    errors.enrutarCredito = "El campo 'Enrutar Crédito' es requerido"
  } else if (!formValues.enrutarCredito.trim('DEFAULT')) {
    errors.enrutarCredito = "El campo 'Enrutar Crédito' es requerido"
  }

  if (!formValues.cuotas.trim()) {
    errors.cuotas = "El campo 'Cuotas' es requerido"
  } else if (!regexNumeroEntero.test(formValues.cuotas.trim())) {
    errors.cuotas = "El campo 'Cuotas' debe mayor a cero sin decimales"
  } else if (formValues.cuotas > formValues.cantidadCuotas) {
    errors.cuotas = "El campo 'Cuotas' supera el máximo permitido"
  }

  if (!formValues.descripcion.trim()) {
    errors.descripcion = "El campo 'Descripción' es requerido"
  } else if (!regexTamano.test(formValues.descripcion.trim())) {
    errors.cuotas = "El campo 'Descripción' debe tener mas de 3 letras"
  }

  if (!formValues.clienteId.trim()) {
    errors.clienteId = "El campo 'Cliente' es requerido"
  } else if (!formValues.clienteId.trim('DEFAULT')) {
    errors.clienteId = "El campo 'Cliente' es requerido"
  }

  if (!formValues.formaPagoId.trim()) {
    errors.formaPagoId = "El campo 'Forma de Pago' es requerido"
  } else if (!formValues.formaPagoId.trim('DEFAULT')) {
    errors.formaPagoId = "El campo 'Forma de Pago' es requerido"
  }

  if (!formValues.generarCuotasHoy.trim()) {
    errors.generarCuotasHoy = "El campo 'Generar Cuotas' es requerido"
  }

  if (formValues.clienteId === '0') {
    if (!formValues.documentoIdentificacion.trim()) {
      errors.documentoIdentificacion =
        "El campo 'Documento de Identificación' es requerido"
    } else if (
      !regexNumeroEnteroMayorCero.test(
        formValues.documentoIdentificacion.trim()
      )
    ) {
      errors.documentoIdentificacion =
        "El campo 'Documento de Identificación' debe mayor a cero sin decimales"
    } /*else if (
      validarDocumentoIdentificacionClienteExistente(
        formValues.documentoIdentificacion
      )
    ) {
      console.log(
        validarDocumentoIdentificacionClienteExistente(
          formValues.documentoIdentificacion
        )
      );
      errors.documentoIdentificacion =
        "El campo 'Documento de Identificación' ya esta registrado en el sistema";
    }*/

    if (!formValues.nombreCompleto.trim()) {
      errors.nombreCompleto = "El campo 'Nombre Completo' es requerido"
    } else if (!regexTamano.test(formValues.nombreCompleto.trim())) {
      errors.nombreCompleto =
        "El campo 'Nombre Completo' debe tener mas de 3 letras"
    } else if (!regexName.test(formValues.nombreCompleto.trim())) {
      errors.nombreCompleto = "El campo 'Nombre Completo' debe contener letras"
    }

    if (!formValues.direccion.trim()) {
      errors.direccion = "El campo 'Dirección' es requerido"
    } else if (!regexTamano.test(formValues.direccion.trim())) {
      errors.direccion = "El campo 'Dirección' debe tener mas de 3 letras"
    }

    if (!formValues.barrio.trim()) {
      errors.barrio = "El campo 'Barrio' es requerido"
    } else if (!regexTamano.test(formValues.barrio.trim())) {
      errors.barrio = "El campo 'Barrio' debe tener mas de 3 letras"
    }

    if (!formValues.celular.trim()) {
      errors.celular = "El campo 'Celular' es requerido"
    } else if (!regexNumeroEnteroMayorCero.test(formValues.celular.trim())) {
      errors.celular = "El campo 'Celular' debe mayor a cero sin decimales"
    } else if (!regexTamanoCelular.test(formValues.celular.trim())) {
      errors.celular = "El campo 'Celular' debe tener mas de 6 dijitos"
    }
  }

  return errors
}

var cupoState = 0

// Componente base
const CreditosView = ({ ...props }) => {
  const [permisosConcedidos, setPermisosConcedidos] = useState(false)
  const [state, dispatch] = useReducer(creditosReducer, creditosInitialState)
  const { db, totalCreditos } = state
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [modalEnrutarVisible, setModalEnrutarVisible] = useState(false)
  const [modalDetalleVisible, setModalDetalleVisible] = useState(false)
  const [modalClienteVisible, setModalClienteVisible] = useState(false)
  const [mostrarNombreClienteNuevo, setMostrarNombreClienteNuevo] =
    useState(false)
  const [dataModalDetalle, setDataModalDetalle] = useState('')

  const [clientes, setClientes] = useState(null)
  const [obtenerClientes, setObtenerClientes] = useState(false)

  const [formasPagos, setFormasPagos] = useState(null)
  const [obtenerFormasPagos, setObtenerFormasPagos] = useState(false)

  const [interesRuta, setInteresRuta] = useState(null)
  const [obtenerInteresRuta, setObtenerInteresRuta] = useState(false)

  const [creditosEnrutados, setCreditosEnrutados] = useState(null)

  const [clienteId, setClienteId] = useState(null)
  const [formularioCliente, setFormularioCliente] = useState(null)

  const usarGpsAsesor = localStorage.getItem('usarGpsAsesor')
  const cantidadCuotas = localStorage.getItem('cantidadCuotas')

  const handleInputBlur = (e) => {
    handleInputChange(e)
    setErrorsForm(validationsForm(formValues))
  }

  const [formValues, handleInputChange, reset, setFormValues] = useForm({
    capital: '',
    enrutarCredito: '',
    cuotas: '',
    descripcion: '',
    clienteId: '',
    formaPagoId: '',
    documentoIdentificacion: '',
    nombreCompleto: '',
    direccion: '',
    barrio: '',
    celular: '',
    latitud: 0,
    longitud: 0,
    cupoCliente: 0,
    cantidadCuotas: cantidadCuotas,
    generarCuotasHoy: '',
  })
  const [errorsForm, setErrorsForm] = useState({})

  useEffect(() => {
    if (usarGpsAsesor === 'true') {
      const obtenerUbicacion = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function (position) {
            console.log('Permiso de GPS concedido:', position)
            setPermisosConcedidos(true)
          })
        } else {
          console.error()
        }
      }
      obtenerUbicacion()
    }
  }, [usarGpsAsesor])

  const handleCrear = () => {
    if (!permisosConcedidos && usarGpsAsesor === 'true') {
      ModalAnt.warning({
        title: 'Sin acceso al GPS',
        content:
          'No se concedieron permisos de ubicación. Contacte al administrador.',
        okText: 'Aceptar',
      })
    } else {
      setModalVisible(true)
      if (!obtenerClientes) {
        setLoading(true)
        let url = URL_BASE_API + '/clientes'
        helpHttp()
          .get(url)
          .then((res) => {
            if (!res.err) {
              setError(null)
              setClientes(res)
              setObtenerClientes(true)
            } else {
              message.error({
                content: `Hubo un error`,
              })
            }
            setLoading(false)
          })
      }

      if (!obtenerFormasPagos) {
        setLoading(true)
        let url = URL_BASE_API + '/formas-pagos'
        helpHttp()
          .get(url)
          .then((res) => {
            if (!res.err) {
              setError(null)
              setFormasPagos(res)
              setObtenerFormasPagos(true)
            } else {
              message.error({
                content: `Hubo un error`,
              })
            }
            setLoading(false)
          })
      }

      if (!obtenerInteresRuta) {
        setLoading(true)
        let url = URL_BASE_API + '/interes-ruta'
        helpHttp()
          .get(url)
          .then((res) => {
            if (!res.err) {
              setError(null)
              setInteresRuta(res[0].interes / 10)
              setObtenerInteresRuta(true)
            } else {
              message.error({
                content: `Hubo un error`,
              })
            }
            setLoading(false)
          })
      }

      peticionHttpCreditosEnrutados()
    }
  }

  const peticionHttpCreditosEnrutados = () => {
    setCreditosEnrutados(null)
    setLoading(true)
    let url = URL_BASE_API + '/creditos-enrutados'
    helpHttp()
      .get(url)
      .then((res) => {
        if (!res.err) {
          setError(null)
          setCreditosEnrutados(res)
        } else {
          message.error({
            content: `Hubo un error`,
          })
        }
        setLoading(false)
      })
  }

  const handleClickEliminar = (e, el) => {
    e.preventDefault()
    ModalAnt.confirm({
      title: `Eliminar Crédito`,
      content: `¿Está seguro de eliminar el crédito del cliente ${
        el.cliente.nombre_completo
      } por valor de ${formatNumber(el.capital)}`,
      cancelText: 'Cancelar',
      okText: 'Eliminar',
      onOk: () => eliminarCredito(el),
    })
  }

  const handleClienteNuevo = (e, o) => {
    formValues.clienteId = e.target.value
    setClienteId(o)
    if (e.target.value === '0') {
      setModalVisible(false)
      setModalClienteVisible(true)
      setMostrarNombreClienteNuevo(true)
    } else {
      setMostrarNombreClienteNuevo(false)
    }
  }

  const handleCupoSelectCliente = (cupo) => {
    cupoState = cupo.data
    formValues.latitud = cupo.latitud
    formValues.longitud = cupo.longitud
  }

  const eliminarCredito = (el) => {
    let url = URL_BASE_API + '/creditos/' + el.id
    setLoading(true)
    helpHttp()
      .del(url)
      .then((res) => {
        if (!res.err) {
          if (res instanceof TypeError) {
            message.error({
              content: `El crédito del cliente ${
                el.cliente.nombre_completo
              } por valor de ${formatNumber(
                el.capital
              )} no fue eliminado, intentelo de nuevo!`,
            })
          } else {
            setError(null)
            dispatch({
              type: types.creditos_eliminar,
              payload: el,
            })
            message.success({
              content: `El crédito del cliente ${
                el.cliente.nombre_completo
              } por valor de ${formatNumber(el.capital)} fue eliminado!`,
            })
          }
        } else {
          message.error({
            content: `Hubo un error, el crédito del cliente ${
              el.cliente.nombre_completo
            } por valor de ${formatNumber(el.capital)} no fue eliminado!`,
          })
        }
        setLoading(false)
      })
  }

  const handleClickDetalle = (el) => {
    setDataModalDetalle(el)
    setModalDetalleVisible(true)
  }

  const closeModal = (e) => {
    e.preventDefault()
    setModalVisible(false)
  }

  const closeModalEnrutar = (e) => {
    e.preventDefault()
    setModalEnrutarVisible(false)
  }

  const closeModalCliente = (e) => {
    e.preventDefault()
    setModalClienteVisible(false)
    setModalVisible(true)
  }

  const abrirModalCliente = (e) => {
    e.preventDefault()
    setModalVisible(false)
    setModalClienteVisible(true)
  }

  const closeModalDetalle = () => {
    setModalDetalleVisible(false)
  }

  const filtroBusqueda = ({ target }) => {
    dispatch({
      type: types.creditos_buscar,
      payload: target.value,
    })
  }

  const limpiarFiltroBusqueda = () => {
    dispatch({
      type: types.creditos_limpiar_filtro,
    })
  }

  const handleClickEnrutarSave = (e, prioridad, el) => {
    e.preventDefault()
    let regexNumeroEntero = /^[1-9]\d*$/
    if (!regexNumeroEntero.test(prioridad)) {
      message.error({
        content: `El valor debe ser entero sin decimales`,
      })
    } else if (el.prioridad === prioridad) {
      message.error({
        content: `La nueva prioridad es igual a la anterior, no se realizó ninguna modificación`,
      })
    } else if (prioridad > creditosEnrutados.length) {
      message.error({
        content: `La nueva prioridad no debe ser mayor a la cantidad de créditos, no se realizó ninguna modificación`,
      })
    } else {
      ModalAnt.confirm({
        title: 'Enrutar Crédito',
        content: `¿Está seguro de cambiar la prioridad actual ${el.prioridad} del cliente ${el.cliente.nombre_completo} por ${prioridad}`,
        cancelText: 'Cancelar',
        okText: 'Cambiar',
        onOk: () => registarEnrutar(prioridad, el),
      })
    }
  }

  const registarEnrutar = (prioridad, el) => {
    let url = URL_BASE_API + '/cambiar-prioridad'
    setLoading(true)
    helpHttp()
      .post(url, {
        body: {
          prioridad: prioridad,
          idCredito: el.id,
        },
      })
      .then((res) => {
        if (!res.err) {
          setError(null)
          handleEnrutar()
          message.success({
            content: `El cambio de prioridad al crédito del cliente ${el.cliente.nombre_completo} por valor ${prioridad} fue registrado con éxito!`,
          })
        } else {
          setError(res)
        }
        setLoading(false)
      })
  }

  const handleEnrutar = () => {
    peticionHttpCreditosEnrutados()
    setModalEnrutarVisible(true)
  }

  const handleCrearClienteSubmit = (e) => {
    setFormularioCliente(e)
    e.preventDefault()
    formValues.latitud = '0'
    formValues.longitud = '0'
    if (
      validarDocumentoIdentificacionClienteExistente(
        formValues.documentoIdentificacion
      )
    ) {
      const erroresLocales = validationsForm(formValues)
      setErrorsForm(erroresLocales)
      if (
        !erroresLocales.hasOwnProperty('documentoIdentificacion') &&
        !erroresLocales.hasOwnProperty('nombreCompleto') &&
        !erroresLocales.hasOwnProperty('direccion') &&
        !erroresLocales.hasOwnProperty('barrio') &&
        !erroresLocales.hasOwnProperty('celular')
      ) {
        //Consultar API si todo esta bien para el documento

        setLoading(true)
        let url =
          URL_BASE_API +
          '/cliente-por-documento/' +
          formValues.documentoIdentificacion
        helpHttp()
          .get(url)
          .then((res) => {
            if (!res.err) {
              setError(null)
              if (res !== false) {
                ModalAnt.confirm({
                  title: `Crear Cliente`,
                  content: (
                    <div>
                      <p>
                        La identificacion # {formValues.documentoIdentificacion}{' '}
                        se encuentra registrado en el sistema bajo el nombre "
                        {res.nombre_completo}", con una calificación negativa
                        por no pagar créditos. ¿Esta seguro de crear el crédito?
                      </p>
                    </div>
                  ),
                  cancelText: 'No',
                  okText: 'Si',
                  onOk: () => {
                    setModalClienteVisible(false)
                    setModalVisible(true)
                  },
                  onCancel: () => false,
                })
              } else {
                setModalClienteVisible(false)
                setModalVisible(true)
              }
            } else {
              message.error({
                content: `Hubo un error`,
              })
            }
            setLoading(false)
          })
      }
    }
  }

  const validarDocumentoIdentificacionClienteExistente = (
    documentoIdentificacion
  ) => {
    let clienteExistente = clientes.filter(
      (usuario) => usuario.documento_identificacion === documentoIdentificacion
    )
    if (clienteExistente.length > 0) {
      message.error({
        content: `El campo 'Documento de Identificación' ya esta registrado en la ruta, no se puede crear el cliente`,
      })
      return false
    }
    return true
  }

  const obtenerCoordenadas = () => {
    const promise = new Promise(function (resolve) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          formValues.latitud = position.coords.latitude
          formValues.longitud = position.coords.longitude
          resolve()
        },
        function (error) {
          console.log(error)
        },
        {
          enableHighAccuracy: true,
          timeout: 30000,
          maximumAge: 0,
        }
      )
    })

    return promise
  }

  const crearCredito = (e) => {
    const promise = new Promise(function (resolve) {
      let url = URL_BASE_API + '/creditos'
      helpHttp()
        .post(url, {
          body: formValues,
        })
        .then((res) => {
          if (!res.err) {
            if (res instanceof TypeError) {
              message.error({
                content: `El crédito no fue creado, intentelo de nuevo!`,
              })
            } else {
              setError(null)
              dispatch({
                type: types.creditos_crear,
                payload: res,
              })
              message.success({
                content: `Crédito para el cliente ${res.cliente.nombre_completo} por valor ${res.capital} fue creado con éxito!`,
              })
            }
            reset()
            e.target.reset()
            setClienteId(
              ((clienteId.label = 'Seleccione Cliente'),
              (clienteId.value = 'DEFAULT'))
            )
            setMostrarNombreClienteNuevo(false)
            if (formularioCliente !== null) {
              setFormularioCliente(formularioCliente.target.reset())
            }
          } else {
            setError(res)
          }
          setModalVisible(false)
        })
      resolve()
    })
    return promise
  }

  const handleCrearSubmit = (e) => {
    e.preventDefault()
    const erroresLocales = validationsForm(formValues)
    setErrorsForm(erroresLocales)

    if (Object.keys(erroresLocales).length === 0) {
      //ESTO SE EJECUTA SI ESTA ACTIVADO USAR_GPS_ASESOR
      setLoading(true)
      if (usarGpsAsesor === 'true') {
        //if ya se tiene la ubicacion del cliente preguntar si se quiere o no actualizar
        if (formValues.longitud !== '0' && formValues.latitud !== '0') {
          ModalAnt.confirm({
            title: `Actualizar Ubicación`,
            content: (
              <div>
                <p>
                  ¿Este cliente ya tiene almacenada la ubicación quiere
                  actualizarla?
                </p>
              </div>
            ),
            cancelText: 'No',
            okText: 'Si',
            onOk: () => {
              obtenerCoordenadas().then(() => {
                if (formValues.longitud !== 0 && formValues.latitud !== 0) {
                  crearCredito(e).then(() => {
                    setLoading(false)
                    setModalVisible(false)
                  })
                } else {
                  message.error({
                    content: `El crédito no puede ser creado porque no se obtuvo la ubicación`,
                  })
                  setLoading(false)
                  setModalVisible(false)
                }
              })
            },
            onCancel: () => {
              crearCredito(e).then(() => {
                setLoading(false)
                setModalVisible(false)
              })
            },
          })
        } else {
          obtenerCoordenadas().then(() => {
            if (formValues.longitud !== 0 && formValues.latitud !== 0) {
              crearCredito(e).then(() => {
                setLoading(false)
                setModalVisible(false)
              })
            } else {
              message.error({
                content: `El crédito no puede ser creado porque no se obtuvo la ubicación`,
              })
              setLoading(false)
              setModalVisible(false)
            }
          })
        }
      } else {
        crearCredito(e).then(() => {
          setLoading(false)
          setModalVisible(false)
        })
      }
    } else {
      return
    }
  }

  let url = URL_BASE_API + '/creditos'
  useEffect(() => {
    setLoading(true)
    helpHttp()
      .get(url)
      .then((res) => {
        if (res !== undefined) {
          if (!res.err) {
            setError(null)
            dispatch({
              type: types.creditos_todos,
              payload: res,
            })
          } else {
            setError(res)
          }
        }
        setLoading(false)
      })
  }, [url])
  return (
    <div {...props}>
      <GroupHeader
        category="Créditos"
        filtroBusqueda={filtroBusqueda}
        limpiarFiltroBusqueda={limpiarFiltroBusqueda}
      />
      <Modal
        type="centered"
        show={modalClienteVisible}
        opacity="0.8"
        w={'20em'}
        closeModalCliente={closeModalCliente}
        handleInputChange={handleInputChange}
        handleInputBlur={handleInputBlur}
        errorsForm={errorsForm}
        handleCrearClienteSubmit={handleCrearClienteSubmit}
      ></Modal>
      <ModalEnrutar
        show={modalEnrutarVisible}
        opacity="0.8"
        w={'20em'}
        closeModal={closeModalEnrutar}
        creditosEnrutados={creditosEnrutados}
        handleClickEnrutarSave={(e, prioridad, el) =>
          handleClickEnrutarSave(e, prioridad, el)
        }
      ></ModalEnrutar>
      <ModalCredito
        type="centered"
        show={modalVisible}
        opacity="0.8"
        w={'20em'}
        closeModal={closeModal}
        handleCrearSubmit={handleCrearSubmit}
        handleInputChange={handleInputChange}
        handleInputBlur={handleInputBlur}
        errorsForm={errorsForm}
        formasPagos={formasPagos}
        creditosEnrutados={creditosEnrutados}
        clientes={clientes}
        handleCupoSelectCliente={handleCupoSelectCliente}
        interesRuta={interesRuta}
        handleClienteNuevo={handleClienteNuevo}
        nombreClienteNuevo={formValues.nombreCompleto}
        mostrarNombreClienteNuevo={mostrarNombreClienteNuevo}
        abrirModalCliente={abrirModalCliente}
      ></ModalCredito>
      <ModalCredito
        type="bottom"
        show={modalDetalleVisible}
        opacity="0.8"
        w={'15em'}
        closeModalDetalle={closeModalDetalle}
        dataModalDetalle={dataModalDetalle}
      ></ModalCredito>
      <GroupTableContainer>
        <GroupTableHeader
          section="Crédito"
          sectionEnrutar="Enrutar"
          button={true}
          handleCrear={handleCrear}
          enrutar={true}
          handleEnrutar={handleEnrutar}
        />
        {db !== null ? (
          db.map((el) => (
            <GroupItemGen
              key={el.id}
              username={el.cliente.nombre_completo}
              userdebt={formatNumber(el.saldo_actual)}
              uservalue={formatNumber(el.capital)}
              iconcolor={ofc.primary}
              valuecolor="green"
              handleClickEliminar={(e) => handleClickEliminar(e, el)}
              textoEliminar="Crédito"
              handleClickDetalle={(e) => handleClickDetalle(el)}
              mostrarSaldo={true}
              cargaAutomaticaCreditos={el.carga_automatica_creditos}
            />
          ))
        ) : (
          <Mensaje
            mensaje="No hay información para mostrar"
            type="info"
            title="Información"
          />
        )}
        {error && (
          <Mensaje
            mensaje={`Error ${error.status}: ${error.statusText}`}
            type="error"
            title="Error"
          />
        )}
      </GroupTableContainer>
      {loading && <Loader />}
      <NavBar
        view="creditos"
        texto1="No. Créditos"
        texto2="Total Créditos"
        totalTexto1={db != null ? db.length : 0}
        totalTexto2={formatNumber(totalCreditos)}
      />
    </div>
  )
}

export default CreditosView
